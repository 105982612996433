"use strict";

import humanizeDuration from "humanize-duration";
import prettyMs from "pretty-ms";

const gUnitsMap = {
  second: 1,
  minute: 60,
  hour: 60 * 60,
  day: 60 * 60 * 24,
  week: 60 * 60 * 24 * 7,
  month: 60 * 60 * 24 * 30,
  year: 60 * 60 * 24 * 365
};

/**
 * @param {string} timesRaw
 * @returns {string}
 */
export function convertTime(timesRaw) {
  const secondsLists = getSecondsLists(timesRaw);
  const secondsTotal = getTotalSeconds(secondsLists);
  const milliseconds = secondsTotal * 1000;
  return {
    outputTime: prettyMs(milliseconds, { colonNotation: true }),
    outputText: humanizeDuration(milliseconds)
  };
}

/**
 * @param {string} timesRaw
 * @returns {number[]}
 */
function getSecondsLists(timesRaw) {
  const lines = timesRaw.split("\n").filter(getIsValidInput);
  return lines.map(linesToSeconds);
}

/**
 * @param {string} line
 * @returns {boolean}
 */
function getIsValidInput(line) {
  return !line.trim() || line.split(":").length <= Object.values(gUnitsMap).length;
}

/**
 * @param {string} line
 * @returns {number}
 */
function linesToSeconds(line) {
  const timesStrings = line.split(":").reverse();
  const iUnits = Object.values(gUnitsMap);
  return timesStrings
    .map((timeRaw, i) => {
      const time = timeRaw.replace(/,/g, "") || 0;
      return iUnits[i] * time;
    })
    .reduce((sum, time) => sum + time, 0);
}

/**
 * @param {number[]} secondsLists
 * @returns {number}
 */
function getTotalSeconds(secondsLists) {
  return secondsLists.reduce((sumTotal, sumCurrent) => sumTotal + sumCurrent, 0);
}
